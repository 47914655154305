import React from "react";
import { Link } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import Navbar from "../components/Navbar";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleChange, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent, setSolutionBanner, solutionBanner, solutions }) => {
    const styles = {
        container: {
            //    position: "fixed",
            //   bottom: 0,
            //  top: 0,
            // left: 0,
            //  right: 0,
            display: "flex",
            flexDirection: "column",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            //    height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            height: "60px",
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: `${screenSize > 800 ? ("0px 9vw") : ("0px")}`,
            padding: '10px 0px',
            // width: '100%'
            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        navbarWhite: {
            // display: "flex",
            // alignItems: 'center',
            // justifyContent: 'space-between',
            // height: "110px",
            // width: '100%',
            // padding: `${screenSize > 800 ? ("20px 60px") : ("20px")}`,
            // margin: "0px",

            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,

            position: "fixed",
            top: 0,
            right: 0,
            bottom: `${screenSize > 800 ? (<></>) : ("0")} `,
            left: 0,
            display: "flex",
            flexDirection: 'row',
            height: "100px",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            margin: "0px",
            zIndex: 200,
            backgroundColor: 'white',
            //     borderBottom: '1px solid rgba(0, 0, 0, .2)',
        },
        logoContainer: {
            display: "flex",
            flex: '1',
            alignItems: "center",
            justifyContent: 'center',
            border: 'none',
            padding: 0,
            outline: 0,
            backgroundColor: 'transparent',
            //    backgroundColor: 'red'
            //   padding: "20px 0px"
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        buttonsNavbarLeft: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-start'
        },
        buttonsNavbarRight: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        buttonNavbarPlain: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: 'none',
            padding: 0,
            outline: 0,
            margin: "0px 15px 0px 0px",
            display: 'flex',
            flexDirection: 'row',
            // alignItem: 'center',
            // justifyContent: 'center'
        },
        textButtonPlain: {
            margin: 0,
            fontSize: 16,
            color: '#4dc2f1',
            margin: "0px 15px 0px 0px",
            fontFamily: "UbuntuRegular",
            //  textAlign: 'start'
        },
        line: {
            display: 'flex',
            width: "30px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#4dc2f1'
        },
        buttonNavbarWhite: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: '2px solid #4dc2f1',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 5px"
        },
        buttonNavbarBlue: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 10px"
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButtonBlue: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuRegular",
        },
        textButtonWhite: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
            fontFamily: "UbuntuRegular",
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuRegular",
            //  color: '#000',
        },
        bannerHeader: {
            display: 'flex',
            flexDirection: "column",
            margin: `${screenSize > 800 ? ('8vh 0px 0px') : ('3vh 0px 0px')}`,
        },
        textsHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        textsSummary: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: `${screenSize > 800 ? ('30px') : ('10px 15px')}`,
        },
        summarySolution: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('20px') : ('14px')}`,
            fontFamily: "Ubuntu",
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('5px 10px')}`,
        },
        solutionHeader: {
            color: '#4dc2f1',
            fontSize: `${screenSize > 800 ? ('20px') : ('16px')}`,
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('0px') : ('0px')}`,
        },
        titleHeader: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('45px') : ('25px')}`,
            fontFamily: "UbuntuMedium",
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('10px 0px 30px')}`,
        },
        subtitleHeader: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('35px') : ('20px')}`,
            fontFamily: "UbuntuMedium",
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('0px 10px 20px')}`,
        },
        subtitleHeaderBenefits: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('35px') : ('20px')}`,
            fontFamily: "UbuntuMedium",
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('30px 15px')}`,
        },
        subtitleHeaderCloud: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('35px') : ('20px')}`,
            fontFamily: "UbuntuMedium",
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('20px 15px 30px')}`,
        },
        titleBanner: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('35px') : ('20px')}`,
            fontFamily: "UbuntuMedium",
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('50px 0px 0px') : ('10px 15px 30px')}`,
        },

        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },

        bannerCase: {
            display: "flex",
            alignSelf: 'center',
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            alignItems: 'center',
            justifyContent: 'center',
            width: '85vw',
            height: "550px",
            margin: "50px 0px",
            padding: "75px",
            //  backgroundColor: "red",
            borderRadius: '50px',
            boxShadow: `${darkModeState ? ("0 3px 16px 0 hsl(228, 28%, 11%)") : ("0 3px 16px 0 rgba(90, 90, 90, 0.1)")}`,
        },
        bannerCase2: {
            display: "flex",
            alignSelf: 'center',
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            alignItems: 'center',
            justifyContent: 'center',
            width: '85vw',
            // height: `${screenSize > 800 ? ("550px") : ("30vh")}`,
            margin: "50px 0px",
            // padding: "75px",
            //  backgroundColor: "red",
            borderRadius: '50px',
            boxShadow: `${darkModeState ? ("0 3px 16px 0 hsl(228, 28%, 11%)") : ("0 3px 16px 0 rgba(90, 90, 90, 0.1)")}`,
        },
        textsCase: {
            display: 'flex',
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '20px',
            //     backgroundColor: "green",
        },
        tipText: {
            fontSize: `${screenSize > 800 ? ('14px') : ('12px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 0px 10px') : ('0px 0px 10px')}`,
            fontFamily: "UbuntuRegular",
            color: '#fff',
            backgroundColor: "#2D2A32",
            borderRadius: '25px',
            padding: '5px 18px',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        imageLogoCase: {
            height: '60px',
            margin: `${screenSize > 800 ? ("20px 0px") : ("10px 0px")}`,
        },
        nameTextCase: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('35px') : ('25px')}`,
            fontFamily: "UbuntuMedium",
            // textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('10px 0px 50px')}`,
        },
        titleTextCase: {
            color: '#4dc2f1',
            fontSize: `${screenSize > 800 ? ('20px') : ('16px')}`,
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            // textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('0px')}`,
        },
        desciptionTextCase: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('18px') : ('16px')}`,
            fontFamily: "UbuntuRegularItalic",
            margin: `${screenSize > 800 ? ('20px 0px 30px') : ('10px 0px 20px')}`,
            padding: `${screenSize > 800 ? ('0px 30% 0px 0px') : ('0px')}`,
            lineHeight: '1.3'
        },
        buttonCase: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 1vw 0px 0px') : ('20px 15vw 20px')}`,
            padding: 0,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        buttonTextCase: {
            color: '#D0B5F0',
            fontSize: '18px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px 15px 0px 0px',
            textAlign: 'start',
        },
        lineHeader: {
            display: 'flex',
            width: "100px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#D0B5F0'
        },














        bannerSolutions: {
            display: "flex",
            flexDirection: 'column',
            margin: `${screenSize > 800 ? ('0px') : ('50px 0px 0px')}`,
            padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            //  height: "70vh",
            textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        titleBannerSolutions: {
            fontSize: `${screenSize > 800 ? ('40px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('-10px 0px 0px') : ('0px 0px 0px')}`,
            fontFamily: "UbuntuRegular",
            color: '#4dc2f1',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        subtitleBannerSolutions: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '50px 0px 40px',
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        subtitleBannerSolutionsText: {
            color: '#000',
            fontSize: '18px',
            fontFamily: 'UbuntuRegular',
            fontWeight: '600',
            margin: '0px'
            //  padding: '0px 5vw'
            // textAlign: 'center'
        },
        buttonBannerSolutions: {
            display: 'flex',
            //     flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '0px 0px 20px',
            backgroundColor: 'transparent',
            border: '1px solid #000',
            borderRadius: '50px',
            outline: 0,
            height: '45px',
            width: '180px',
        },
        buttonBannerSolutionsText: {
            // display: 'flex',
            // alignSelf: 'center',
            color: '#000',
            fontSize: '14px',
            fontFamily: 'UbuntuRegular',
            fontWeight: '600',
            margin: '0px',
            //    padding: '13px 20px',
            //    padding: '3px 18px',

            // textAlign: 'center'
        },




        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('100px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
        footerContainer: {
            display: "flex",
            margin: "30px 15px",
            alignItems: "center",
            justifyContent: 'center',
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("center")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        svgStyle: {
            height: 16,
            width: 16,
            display: 'flex',
            alignSelf: 'center'
        },
        // toogleNavbar: {
        //     display: "flex",
        //     flex: 4,
        //     alignItems: "center",
        //     justifyContent: `${ screenSize > 800 ? ("flex-end") : ("center") } `,
        //     flexDirection: `${ screenSize > 800 ? ("row") : ("column") } `,
        // },
        // textToogle: {
        //     fontSize: "14px",
        //     fontFamily: "InterBold",
        //     alignSelf: "center",
        //     textDecoration: "none",
        //     color: 'inherit',
        //     border: 'none',
        //     outline: 'none',
        //     padding: '12px',
        //     backgroundColor: `transparent`
        // }
        bannerAvaya: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
            margin: `${screenSize > 800 ? ("0px") : ("50px 0px 0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#DA291C'
        },
        columnTextAvaya: {
            display: "flex",
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("0px 40px") : ("60px 20px 0px")}`,
            padding: `${screenSize > 800 ? ("70px 0px") : ("0px")}`,
        },
        textAvaya: {
            fontFamily: 'sans-serif',
            fontWeight: 400,
            fontSize: '22px',
            color: '#fff',
            lineHeight: '1.5',
            textAlign: 'center',
            width: '100%',
            margin: 0
        },

        bannerPartners: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
            //     flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
            margin: `${screenSize > 800 ? ("0px 0px 50px") : ("50px 0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#2D2A32',
            height: `${screenSize > 800 ? ("250px") : (<></>)}`,
            padding: `${screenSize > 800 ? ("0px 10vw") : ("50px 0px 40px")}`,
            width: "100vw"
        },
        columnTextPartners: {
            display: "flex",
            flex: 1,
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("0px") : ("0px")}`,
            width: `${screenSize > 800 ? ("100px") : ("80vw")}`,
            margin: `${screenSize > 800 ? ("0px 3vw 0px 0px") : ("0px 20px 20px")}`,

        },
        textPartners: {
            fontFamily: 'UbuntuMedium',
            fontSize: `${screenSize > 800 ? ("30px") : ("22px")}`,
            color: '#fff',
            lineHeight: `${screenSize > 800 ? ("1.3") : ("1.5")}`,
            textAlign: `${screenSize > 800 ? ("end") : ("center")}`,
            margin: 0
        },
        linePartners: {
            display: 'flex',
            width: "2px",
            height: "130px",
            alignSelf: 'center',
            backgroundColor: '#4dc2f1',
            margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        imagePartnersDiv: {
            display: "flex",
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("0px") : ("20px 0px")}`,
        },
        imagePartners: {
            width: `${screenSize > 800 ? ("60%") : ("30%")}`,
            objectFit: "cover",
            //  margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        experiencesBanner: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            margin: `${screenSize > 800 ? ('20px 10vw 50px') : ('0px 30px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            // alignItems: "center",
            justifyContent: 'space-between',
            // height: "600px",
            //   backgroundColor: "blue",
            // textAlign: 'center',
            flexWrap: `${screenSize > 800 ? ('no-wrap') : ('wrap')}`,
        },
        cloudBanner: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            margin: `${screenSize > 800 ? ('20px 10vw 80px') : ('0px 30px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            // alignItems: "center",
            justifyContent: 'space-between',
            // height: "600px",
            //   backgroundColor: "blue",
            // textAlign: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        singleExperience: {
            display: "flex",
            flex: "1",
            flexDirection: "row",
            margin: `${screenSize > 800 ? ('0px') : ('0px 10px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: `${screenSize > 800 ? (<></>) : ('center')}`,
            justifyContent: `${screenSize > 800 ? (<></>) : ('center')}`,
            //   height: "450px",
            // width: `${screenSize > 800 ? ('30%') : ('100%')}`,
            //    backgroundColor: "red",
            borderRadius: '25px',
            // textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        singleCloudSolution: {
            display: "flex",
            // flex: "1",
            flexDirection: "row",
            margin: `${screenSize > 800 ? ('0px') : ('0px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: `${screenSize > 800 ? (<></>) : ('center')}`,
            justifyContent: `${screenSize > 800 ? (<></>) : ('center')}`,
            //   height: "450px",
            width: `${screenSize > 800 ? ('30%') : ('100%')}`,
            //    backgroundColor: "red",
            borderRadius: '25px',
            // textAlign: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        benefitsBannerGroup: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            margin: `${screenSize > 800 ? ('0px 0px 80px') : ('0px')}`,
            justifyContent: 'space-between',
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            // alignItems: "center",
            // height: "600px",
            //   backgroundColor: "blue",
            // textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
            // margin: "20px"
        },
        benefitsBannerGroupText: {
            display: "flex",
            flex: 3,
            flexDirection: "column",
            // margin: `${screenSize > 800 ? ('0px') : ('0px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            // // alignItems: "center",
            // justifyContent: 'space-between',
            // height: "600px",
            //   backgroundColor: "blue",
            // textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
            // margin: "20px"
        },
        benefitsBanner: {
            display: "flex",
            flex: 1,
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            margin: `${screenSize > 800 ? ('20px 20px 50px') : ('0px 30px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            // alignItems: "center",
            justifyContent: 'center',
            // height: "600px",
            //   backgroundColor: "blue",
            // textAlign: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
            // margin: "20px"
        },
        singleResource: {
            display: "flex",
            flexDirection: "column",
            margin: `${screenSize > 800 ? ('60px 0px 0px') : ('20px 0px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: `${screenSize > 800 ? (<></>) : ('center')}`,
            justifyContent: `${screenSize > 800 ? (<></>) : ('center')}`,
            //   height: "450px",
            width: `${screenSize > 800 ? ('30%') : ('100%')}`,
            //    backgroundColor: "red",
            borderRadius: '25px',
            // textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        singleBenefits: {
            display: "flex",
            margin: `${screenSize > 800 ? ('20px 0px 0px') : ('0px 0px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: `${screenSize > 800 ? (<></>) : ('center')}`,
            justifyContent: `${screenSize > 800 ? (<></>) : ('center')}`,
            //   height: "450px",
            width: `${screenSize > 800 ? ('30%') : ('100%')}`,
            //    backgroundColor: "red",
            borderRadius: '25px'
            // textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        singleBenefitsTexts: {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            padding: `${screenSize > 800 ? ('0px 25px') : ('10px 10px')}`,
        },
        singleResourceTexts: {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            padding: `${screenSize > 800 ? ('25px') : ('10px 15px')}`,
        },
        singleCloudSolutionTexts: {
            display: "flex",
            flex: 6,
            flexDirection: "column",
            margin: 0,
            padding: `${screenSize > 800 ? ('25px 10px') : ('10px 15px')}`,
        },
        typeResource: {
            fontSize: `${screenSize > 800 ? ('14px') : ('14px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 0px 10px') : ('0px 0px 20px')}`,
            fontFamily: "UbuntuMedium",
            // color: '#fff',
            color: '#4dc2f1',
            // backgroundColor: "#2D2A32",
            // borderRadius: '25px',
            // padding: '5px 18px',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        titleResource: {
            fontSize: `${screenSize > 800 ? ('20px') : ('18px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 0px 10px') : ('0px 0px 10px')}`,
            fontFamily: "UbuntuBold",
            color: '#000',
            textAlign: `${screenSize > 800 ? (<></>) : ('start')}`,
        },
        subtitleResource: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('16px') : ('14px')}`,
            fontFamily: 'UbuntuRegular',
            margin: `${screenSize > 800 ? ('0px 0px 20px') : ('0px 0px 20px')}`,
            // lineHeight: '30px'
            textAlign: `${screenSize > 800 ? (<></>) : ('start')}`,
        },
        buttonResource: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 1vw 0px 0px') : ('0px 0px 0px')}`,
            padding: 0,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        buttonTextResource: {
            color: '#D0B5F0',
            fontSize: '18px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px 15px 0px 0px',
            textAlign: 'start',
        },
        lineResource: {
            display: 'flex',
            width: "100px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#D0B5F0'
        },



        bannerMeet: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("column") : ("column")}`,
            margin: `${screenSize > 800 ? ("0px") : ("50px 0px 0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#F7F7F5',
            //     height: `${screenSize > 800 ? ("") : (<></>)}`,
            padding: `${screenSize > 800 ? ("100px 0px") : ("50px 0px")}`,
            width: "100vw"
        },
        imageMeetDiv: {
            display: "flex",
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            margin: "0px 0px 20px"
        },
        imageMeet: {
            width: '100px',
            objectFit: "cover",
            //  margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        columnTextMeet: {
            display: "flex",
            flex: 1,
            flexDirection: `${screenSize > 800 ? ("column") : ("column")}`,
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("0px") : ("0px")}`,
            width: `${screenSize > 800 ? ("35vw") : ("70vw")}`,
            margin: `${screenSize > 800 ? ("0px") : ("0px 0px")}`,

        },
        textMeetName: {
            fontFamily: 'UbuntuMedium',
            fontSize: `${screenSize > 800 ? ("20px") : ("16px")}`,
            color: '#4dc2f1',
            lineHeight: '1.3',
            textAlign: 'center',
            margin: `${screenSize > 800 ? ("30px 0px 20px") : ("0px 0px 10px")}`,
        },
        textMeetTitle: {
            fontFamily: 'UbuntuMedium',
            fontSize: `${screenSize > 800 ? ("35px") : ("18px")}`,
            color: '#000',
            lineHeight: '1.3',
            textAlign: 'center',
            margin: "10px 0px 20px"
        },
        textMeetSubtitle: {
            fontFamily: 'UbuntuRegular',
            fontSize: `${screenSize > 800 ? ("18px") : ("16px")}`,
            color: '#000',
            lineHeight: '1.3',
            textAlign: 'center',
            margin: "2px"
        },

        buttonMeetGroup: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ('50px 0px 0px') : ('0px')}`,
            width: `${screenSize > 800 ? ("600px") : (<></>)}`,
            height: '60px',
        },
        imageButtonDiv: {
            display: "flex",
            alignSelf: 'flex-end',
            alignItems: 'center',
            //   justifyContent: 'flex-end',
            //   margin: "0px 0px 20px"
        },
        imageButton: {
            height: '45px',
            objectFit: "cover",
            //  margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        imageButtonDivMobile: {
            display: "flex",
            alignSelf: 'center',
            alignItems: 'center',
            margin: '25px 0px'
        },
        imageButtonMobile: {
            height: '60px',
            objectFit: "cover",
            //  margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        buttonMeet: {
            display: 'flex',
            //       flex: 3,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ('0px 20px') : ('0px')}`,

            backgroundColor: 'transparent',
            border: '1.5px solid #000',
            borderRadius: '50px',
            outline: 0,
            //     padding: '20px 40px'
            height: '45px',
            width: '180px',
        },
        buttonMeetText: {
            color: '#000',
            fontSize: '14px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px',
        },



        columnSolutions: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 10px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },

        icons: {
            display: "flex",
            flex: '1',
            alignSelf: 'start',
            alignItems: "flex-end",
            justifyContent: "flex-end",
            margin: "25px 0px",
            width: '25px',
            height: '25px',
            color: "#4dc2f1",
        },
    };
    return (
        <>
            <div style={styles.container} id="scroller">

                <Navbar style={{ zIndex: 999 }}
                    screenSize={screenSize}
                    language={language}
                    handleClick={handleClick}
                    handleLanguage={handleLanguage}
                    origin="contact"
                />



                <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100vw',
                    //   height: "100vh",
                    margin: 0,
                    padding: 0,
                    backgroundColor: "#fff",
                    //        backgroundColor: '#F7F7F5',
                }}
                    id="home"
                >
                    <div style={{ backgroundColor: 'transparent', height: "100px", width: '100vw' }}></div>
                    <div style={styles.bannerHeader}>
                        <div style={styles.textsHeader}>
                            <h3 style={styles.solutionHeader}>{language === true ? "Solución" : "Solution"}</h3>
                            <h3 style={styles.titleHeader}>{language === true ? "Inteligencia Artificial" : "Artificial Intelligence"}</h3>
                            <h3 style={styles.subtitleHeader}>{language === true ? "Revolucionamos las Experiencias" : "Enhance the customer experience."}</h3>
                        </div>
                        <div style={styles.textsSummary}>
                            <h3 style={styles.summarySolution}>{language === true ? "Impulsamos la IA para potenciar los resultados y redefinir la experiencia del cliente." : "Explore our collection of resources to help you build your business"}</h3>
                            <h3 style={styles.summarySolution}>{language === true ? "Mejoramos cada interacción, creando conexiones más significativas." : "Explore our collection of resources to help you build your business"}</h3>
                        </div>
                    </div>
                </div>




                {/* <div style={styles.bannerCase} id="case" >
                    <div style={styles.textsCase}>
                        <div style={styles.tipText}>{language === true ? "Caso de éxito" : "Success case"}</div>
                        <img
                            src="https://i.postimg.cc/XvDBNLgT/dia.png"
                            style={styles.imageLogoCase}
                            alt="Logo"
                            loading="lazy"
                        />
                        <h3 style={styles.nameTextCase}>Marcela Gutierrez</h3>
                        <h3 style={styles.titleTextCase}>{language === true ? "Líder de SAC" : "Líder de SAC"}</h3>
                        <h3 style={styles.desciptionTextCase}>{language === true ? "”Hemos disminuído el tiempo de atención 6 veces y maximizamos la satisfacción de nuestros clientes en un 40%”" : "Brinda respuestas en el momento"}</h3>
                        <Link style={styles.buttonCase} to="/meet">
                            <h3 style={styles.buttonTextCase}>{language === true ? "SER EL PRÓXIMO CASO DE ÉXITO" : "BE THE NEXT SUCCESS STORY"}</h3>
                            <div style={styles.lineHeader} />
                        </Link>
                    </div>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        backgroundImage: `url(${language === true ? solutions[solutionBanner - 1].urlImage : solutions[solutionBanner - 1].urlImageEnglish})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        margin: 0,
                        padding: 0,
                        borderRadius: '25px',
                        height: `${screenSize > 800 ? ("100%") : ("350px")} `,
                        width: `${screenSize > 800 ? ("100%") : ("100%")} `,
                        objectFit: 'cover'
                    }} />
                </div> */}




                <div style={styles.bannerCase2} id="case" >

                    <video
                        // autoPlay
                        // loop
                        controls
                        // muted
                        playsInline
                        style={{
                            // position: "absolute",
                            // zIndex: "10",
                            // top: 0,
                            // left: 0,
                            // width: '100px',
                            // height: '100px',
                            // objectFit: 'cover',
                            display: "flex",
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            // height: `${screenSize > 800 ? ('100%') : ('100%')}`,
                            width: `${screenSize > 800 ? ('100%') : ('100%')}`,
                            padding: '0px',
                            backgroundColor: '#fff',
                            borderRadius: `${screenSize > 800 ? ('50px') : ('10px')}`,
                            //    margin: '0px 0px 20px'
                        }}>
                        <source src='/assets/videos/PresentationIA.mp4' type="video/mp4" />
                    </video>
                    {/* <div style={{
                        display: "flex",
                        flex: 1,
                        backgroundImage: `url(${language === true ? solutions[solutionBanner - 1].urlImage : solutions[solutionBanner - 1].urlImageEnglish})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        margin: 0,
                        padding: 0,
                        borderRadius: '25px',
                        height: `${screenSize > 800 ? ("100%") : ("100%")} `,
                        width: `${screenSize > 800 ? ("100%") : ("100%")} `,
                        objectFit: 'cover'
                    }} /> */}
                </div>






                {screenSize > 800 ? (
                    <h3 style={styles.titleBanner}>{language === true ? (<>Descubre cómo<span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 15px 0px 30px", fontWeight: '400', color: "#4dc2f1" }}>transformamos</span>su experiencia</>) : (<>Discover how we<span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 15px 0px 30px", fontWeight: '400', color: "#4dc2f1" }}>transform</span>their experience</>)}</h3>
                ) : (
                    <h3 style={styles.titleBanner}>{language === true ? (<>Descubre cómo<span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>transformamos</span>su experiencia</>) : (<>Discover how we<span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 5px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>transform</span>their experience</>)}</h3>
                )}
                <div style={styles.experiencesBanner}>
                    <div style={styles.singleExperience}>
                        <svg style={styles.icons} fill="currentColor" class="bi bi-car-front" viewBox="0 0 16 16">
                            <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0m10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2zM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17s2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276" />
                            <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679q.05.242.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.8.8 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.8.8 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155s4.037-.084 5.592-.155A1.48 1.48 0 0 0 15 9.611v-.413q0-.148-.03-.294l-.335-1.68a.8.8 0 0 0-.43-.563 1.8 1.8 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3z" />
                        </svg>
                        <div style={styles.singleCloudSolutionTexts}>
                            <h3 style={styles.titleResource}>Seguros</h3>
                            <h3 style={styles.subtitleResource}>Con la integración de IA, la resolución de incidentes, reclamos y consultas nunca había sido tan simple y rápida.</h3>
                        </div>
                    </div>
                    <div style={styles.singleExperience}>
                        <svg style={styles.icons} fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
                            <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z" />
                        </svg>
                        <div style={styles.singleCloudSolutionTexts}>
                            <h3 style={styles.titleResource}>Finanzas</h3>
                            <h3 style={styles.subtitleResource}>Las soluciones basadas en IA permiten tomar decisiones más eficaces y en tiempo real.</h3>
                        </div>
                    </div>
                    <div style={styles.singleExperience}>
                        <svg style={styles.icons} fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                        <div style={styles.singleCloudSolutionTexts}>
                            <h3 style={styles.titleResource}>Retail</h3>
                            <h3 style={styles.subtitleResource}>Estas soluciones facilitan el seguimiento del cliente y su intención de compra, optimizando los procesos.</h3>
                        </div>
                    </div>
                </div>








                <div style={styles.benefitsBannerGroup}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        alignSelf: "center",
                        backgroundImage: `url(https://i.postimg.cc/NfRDmz9S/contactcenter-benefits.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        // margin: "0px 0px 0px 50px",
                        margin: "0px 0px 0px 50px",
                        height: `${screenSize > 800 ? ("70vh") : ("350px")} `,
                        // width: `${screenSize > 800 ? ("20vw") : ("100%")} `,
                        objectFit: 'cover'
                    }} />

                    <div style={styles.benefitsBannerGroupText}>

                        {screenSize > 800 ? (
                            <h3 style={styles.subtitleHeaderBenefits}>{language === true ? (<>Beneficios<span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 30px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>reales</span></>) : (<>Real<span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 5px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>benefits</span></>)}</h3>
                        ) : (
                            <h3 style={styles.subtitleHeaderBenefits}>{language === true ? (<>Beneficios<span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>reales</span></>) : (<>Real<span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 5px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>benefits</span></>)}</h3>
                        )}
                        <div style={styles.benefitsBanner}>

                            <div style={styles.singleBenefits}>
                                {/* <img
                            src="https://i.postimg.cc/zvS3jRYk/banner-news-03.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('50px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("10%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        /> */}
                                <div style={styles.singleBenefitsTexts}>
                                    <h3 style={styles.titleResource}>Chatbots más eficientes</h3>
                                    <h3 style={styles.subtitleResource}>Nuestros chatbots están impulsados por IA para ofrecer interacciones conversacionales más naturales, mejorando la experiencia del cliente y facilitando la resolución de consultas.</h3>
                                </div>
                            </div>
                            <div style={styles.singleBenefits}>
                                {/* <img
                            src="https://i.postimg.cc/hG6JPB0V/banner-news-04.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('50px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("10%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        /> */}
                                <div style={styles.singleBenefitsTexts}>
                                    <h3 style={styles.titleResource}>Analítica Emocional</h3>
                                    <h3 style={styles.subtitleResource}>Utilizamos IA para analizar las emociones de los clientes durante las interacciones, proporcionando información valiosa para comprender el sentimiento general y mejorar su satisfacción.</h3>
                                </div>
                            </div>
                            <div style={styles.singleBenefits}>
                                {/* <img
                            src="https://i.postimg.cc/q7dXB2Mk/nota1-2x.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('50px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("10%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        /> */}
                                <div style={styles.singleBenefitsTexts}>
                                    <h3 style={styles.titleResource}>Asistencia a Agentes Humanos</h3>
                                    <h3 style={styles.subtitleResource}>La IA asiste a los agentes humanos proporcionando información relevante en tiempo real, mejorando la eficiencia y permitiendo respuestas más rápidas y precisas.</h3>
                                </div>
                            </div>

                            <div style={styles.singleBenefits}>
                                {/* <img
                            src="https://i.postimg.cc/hG6JPB0V/banner-news-04.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('50px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("10%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        /> */}
                                <div style={styles.singleBenefitsTexts}>
                                    <h3 style={styles.titleResource}>Optimización de Recursos</h3>
                                    <h3 style={styles.subtitleResource}>Los chatbots no solo mejoran la experiencia del cliente, sino que también optimizan los recursos al abordar consultas comunes, permitiendo que los agentes humanos se enfoquen en situaciones más complejas y estratégicas.</h3>
                                </div>
                            </div>


                            <div style={styles.singleBenefits}>
                                {/* <img
                            src="https://i.postimg.cc/RZnhs8mF/banner-news-05.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('50px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("10%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        /> */}
                                <div style={styles.singleBenefitsTexts}>
                                    <h3 style={styles.titleResource}>Transcripción Rápida y Accesible</h3>
                                    <h3 style={styles.subtitleResource}>La tecnología de transcripción basada en IA garantiza una transcripción rápida y precisa de las conversaciones, facilitando la revisión y el análisis de las conversaciones de manera más simple.</h3>
                                </div>
                            </div>
                            <div style={styles.singleBenefits}>
                                {/* <img
                            src="https://i.postimg.cc/cCjwpnbG/banner-news-01.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('50px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("10%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        /> */}
                                <div style={styles.singleBenefitsTexts}>
                                    <h3 style={styles.titleResource}>Mejora Continua</h3>
                                    <h3 style={styles.subtitleResource}>La recopilación y análisis de datos generados por las soluciones basadas en IA permiten dentificar patrones, tendencias y áreas de oportunidad.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div style={styles.bannerPartners}>
                    <div style={styles.columnTextPartners}>
                        {language === true ? <h2 style={styles.textPartners}>Construyendo relaciones <span style={{ fontFamily: "UbuntuRegular" }}>Partners</span></h2> : <h2 style={styles.textPartners}>Building relationships <span style={{ fontFamily: "UbuntuRegular" }}>Partners</span></h2>}
                    </div>
                    {screenSize > 800 ? (<div style={styles.linePartners} />) : (<></>)}
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/yNKCp2km/logo-avaya-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/Nf1c8g0s/logo-collab-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/J0X5DYMn/logo-cirion-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/FFkd4JTT/logo-claro-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                </div>



                {screenSize > 800 ? (
                    <h3 style={styles.subtitleHeaderCloud}>{language === true ? (<>Soluciones<span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 30px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>cloud</span></>) : (<><span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 5px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>cloud</span>solutions</>)}</h3>
                ) : (
                    <h3 style={styles.subtitleHeaderCloud}>{language === true ? (<>Soluciones<span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>cloud</span></>) : (<><span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 5px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>cloud</span>solutions</>)}</h3>
                )}
                <div style={styles.cloudBanner}>
                    <div style={styles.singleCloudSolution}>
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>OneBot</h3>
                            <h3 style={styles.titleResource}>Brinda respuestas en el momento</h3>
                            <h3 style={styles.subtitleResource}>Solución automática con Inteligencia Artificial para el Servicio al Cliente en canales digitales. Brinda soluciones en el momento de manera eficaz y agiliza los tiempos de atención.</h3>
                        </div>
                    </div>
                    <div style={styles.singleCloudSolution}>
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>OneFeel</h3>
                            <h3 style={styles.titleResource}>Procesa las emociones de las conversaciones</h3>
                            <h3 style={styles.subtitleResource}>Explora las emociones de tus clientes con nuestro análisis completo del sentimiento. Descubre claves valiosas para mejorar la lealtad y la satisfacción.</h3>
                        </div>
                    </div>
                    <div style={styles.singleCloudSolution}>
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>OneAssist</h3>
                            <h3 style={styles.titleResource}>Asiste a los agentes humanos con IA</h3>
                            <h3 style={styles.subtitleResource}>Mejora la calidad de las conversaciones con los clientes con sugerencias en tiempo real de respuestas y próximos pasos recomendados.</h3>
                        </div>
                    </div>
                    <div style={styles.singleCloudSolution}>
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>OneTraining</h3>
                            <h3 style={styles.titleResource}>Capacita de manera eficiente</h3>
                            <h3 style={styles.subtitleResource}>Ofrece formación a los agentes que interactúan con los clientes, incluyendo simulaciones de conversaciones y la posibilidad de consultar conocimientos.</h3>
                        </div>
                    </div>
                    <div style={styles.singleCloudSolution}>
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>OneTraslate</h3>
                            <h3 style={styles.titleResource}>Traduce conversaciones en tiempo real</h3>
                            <h3 style={styles.subtitleResource}>Mejora la conversación con los clientes, traduciendo al instante cada conversación de cualquier parte del mundo.</h3>
                        </div>
                    </div>
                    <div style={styles.singleCloudSolution}>
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>OneSummary</h3>
                            <h3 style={styles.titleResource}>Resume conversaciones de manera eficaz</h3>
                            <h3 style={styles.subtitleResource}>Obten resumenes objetivos y concretos luego de cada interacción con los clientes. Proceso automatizado para el ahorro de tiempo.</h3>
                        </div>
                    </div>


                </div>





                <div style={styles.bannerMeet} id="contact">
                    <div style={styles.imageMeetDiv}>
                        <img
                            src="https://i.postimg.cc/fywvyNs0/profile-image.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imageMeet}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.columnTextMeet}>
                        {language === true ? <h2 style={styles.textMeetName}>Hola, soy Thomas 🖐</h2> : <h2 style={styles.textMeetName}>Hi, I'm Thomas 🖐</h2>}
                        {language === true ? <h2 style={styles.textMeetTitle}>Ayudo a organizaciones a mejorar <span style={{ textDecoration: "underline 2px" }}>el contacto</span> con sus clientes.</h2> : <h2 style={styles.textMeetTitle}>I help organizations to improve <span style={{ textDecoration: "underline 2px" }}>contact</span> with their customers.</h2>}
                        {language === true ? <h2 style={styles.textMeetSubtitle}>Hablemos y busquemos la mejor solución para tu negocio.</h2> : <h2 style={styles.textMeetSubtitle}>Let's talk and find the best solution for your business.</h2>}
                        {language === true ? <h2 style={styles.textMeetSubtitle}>Agenda una reunión gratuita de 30 minutos.</h2> : <h2 style={styles.textMeetSubtitle}>Schedule a free 30 minute meeting.</h2>}
                    </div>


                    {screenSize > 800 ? (
                        <></>
                    ) : (
                        <div style={styles.imageButtonDivMobile}>
                            <img
                                src="https://i.postimg.cc/gcCvDfCH/arrow-vertical.png"
                                style={styles.imageButtonMobile}
                                alt="Logo"
                                loading="lazy"
                            />
                        </div>
                    )}


                    <div style={styles.buttonMeetGroup}>
                        {screenSize > 800 ? (
                            <div style={styles.imageButtonDiv}>
                                <img
                                    src="https://i.postimg.cc/v82yW7qC/arrow-button.png"
                                    style={styles.imageButton}
                                    alt="Logo"
                                    loading="lazy"
                                />
                            </div>
                        ) : (<></>)}
                        <Link style={styles.buttonMeet}
                            // to="/meet"
                            onClick={() => (window.open("https://insideone.typeform.com/to/uRK4cGWA"))}
                        >
                            {language === true ? <h3 style={styles.buttonMeetText}>AGENDAR REUNIÓN</h3> : <h3 style={styles.buttonMeetText}>SCHEDULE MEETING</h3>}
                        </Link>
                        {screenSize > 800 ? (<div style={{ display: "flex", width: '94px' }}></div>) : (<></>)}

                    </div>
                </div>



                {/* <div style={styles.columnAddressContainer}>
                    <div style={styles.singleAddress}>
                        <h1 style={styles.titleAddress}>ARGENTINA</h1>
                        <h1 style={styles.addressText}>Diogenes Taborda 127</h1>
                        <h1 style={styles.addressText}>Distrito Tecnológico, Buenos Aires</h1>
                        <a href="tel:0800 345 4466" style={styles.phoneText}> 0800 345 4466</a>
                    </div>
                    <div style={styles.singleAddress}>
                        {language === true ?
                            <h1 style={styles.titleAddress}>ESPAÑA</h1> :
                            <h1 style={styles.titleAddress}>SPAIN</h1>}
                        <h1 style={styles.addressText}>Calle Montesa 43</h1>
                        <h1 style={styles.addressText}>Salamanca, Madrid</h1>
                        <a href="tel:+34915774549" style={styles.phoneText}>+34 91 577 4549</a>
                    </div>
                    <div style={styles.singleAddress}>
                        {language === true ?
                            <h1 style={styles.titleAddress}>REINO UNIDO</h1> :
                            <h1 style={styles.titleAddress}>UNITED KINGDOM</h1>}
                        <h1 style={styles.addressText}>44 Kirkgate</h1>
                        <h1 style={styles.addressText}>Ripon, North Yorkshire</h1>
                    </div>
                    <div style={styles.singleAddress}>
                        {language === true ?
                            <h1 style={styles.titleAddress}>ESTADOS UNIDOS</h1> :
                            <h1 style={styles.titleAddress}>UNITED STATES</h1>}
                        <h1 style={styles.addressText}>3401 SW 160th Ave. Suite 330</h1>
                        <h1 style={styles.addressText}>Miami</h1>
                    </div>
                </div> */}
                <div style={styles.footerContainer}>
                    <h1 style={styles.textFooter}>{`Copyright © ${year} InsideOne SL. All Rights Reserved.`}</h1>
                </div>
            </div>
        </>
    );
};
